export const Entrepreneursitemsar = [
    {
        id: 1,
        title: "خديجة جلولي: مبتكرة تونسية ملتزمة باستقلالية الأشخاص ذوي الإعاقة الحركية",
        date: "",
        content: `<b>خديجة جلولي: مبتكرة تونسية ملتزمة باستقلالية الأشخاص ذوي الإعاقة الحركية</b><br/>
        <br/>
خديجة جلولي هي رائدة أعمال تونسية تعاني من إعاقة، معروفة بالتزامها بتحسين تنقل الأشخاص ذوي الإعاقة الحركية. وهي المؤسسة المشاركة والمديرة العامة لشركة هاوكار، حيث تعمل على تقديم حلول النقل المناسبة والقابلة للوصول.<br/><br/>
<b>السياق والتكوين</b><br/><br/>
حاصلة على شهادة من جامعة الصناعات الغذائية بتونس (ESIAT) مع إجازة تطبيقية في الصناعات والعمليات الغذائية، كانت خديجة جلولي أيضًا متورطة بشكل كبير في العديد من الجمعيات، بما في ذلك AIESEC. أنشأت شركة ناشئة صغيرة لتعزيز روح ريادة الأعمال لدى الطلاب، مؤكدة التزامها بحل المشكلات الواقعية. هذه التجربة، بالإضافة إلى مسيرتها الشخصية، حفزتها لتطوير حلول لتحسين استقلالية الأشخاص ذوي الإعاقة الحركية.<br/><br/>
في الكرسي المتحرك، واجهت خديجة العديد من حواجز التنقل في تونس. لاحظت أن البنية التحتية ووسائل النقل العامة لم تكن مناسبة للأشخاص مثلها، مما دفعها إلى إجراء تغييرات هامة في هذا المجال.<br/><br/>
<b>تأسيس هاوكار والدوافع</b><br/><br/>
في عام 2017، شاركت خديجة جلولي في تأسيس هاوكار مع سيف الدين عيسى. تتخصص شركتهم في إنشاء مركبات كهربائية مناسبة للأشخاص ذوي الإعاقة الحركية. الهدف من هاوكار هو سد الفجوات في البنية التحتية في تونس من خلال تقديم حلول تنقل مستدامة وقابلة للوصول. ظهر أول نموذج أولي لهم في عام 2018، مما شكل نقطة تحول في الوصول إلى التنقل للأشخاص ذوي الإعاقة.<br/><br/>
<b>النهج والرؤية</b><br/><br/>
تتميز هاوكار بنهجها المعياري: يمكن تخصيص المركبات وفقًا لاحتياجات كل مستخدم. تأمل خديجة جلولي في ديمقراطية التنقل للأشخاص ذوي الإعاقة في تونس وعلى المستوى الدولي. بالنسبة لها، يجب أن تكون "حرية التنقل" حقًا للجميع، وهدف عملها هو تحقيق هذه الرؤية.<br/><br/>
<b>التحديات والابتكارات</b><br/><br/>
لم يكن طريق هاوكار خاليًا من العقبات. فقد أدت غياب الإطار القانوني في تونس للشركات الناشئة ذات الأثر الاجتماعي إلى تعقيد تطوير الشركة. ومع ذلك، استطاعت خديجة وفريقها الابتكار، حيث صمموا على سبيل المثال، مركبة كهربائية يمكن شحنها على المقابس الكهربائية التقليدية، مما جعل هذه التكنولوجيا أكثر وصولاً.<br/><br/>
<b>الاعتراف الدولي والأثر</b><br/><br/>
جذبت ابتكارات هاوكار انتباه العالم على المستوى الدولي. في يونيو 2024، عرضت خديجة جلولي عملها في الأمم المتحدة خلال الدورة السابعة عشرة لمؤتمر الدول الأطراف في اتفاقية حقوق الأشخاص ذوي الإعاقة (COSP17). وأكدت أهمية دمج الأشخاص ذوي الإعاقة في القرارات المتعلقة بهم، داعية إلى إدماج حقيقي وفعال.<br/><br/>
حازت هاوكار على العديد من الجوائز المرموقة، بما في ذلك جائزة Energy Globe في عام 2021، بالإضافة إلى جائزة التميز الأفريقي للمركبات المبتكرة والصديقة للبيئة.<br/><br/>
<b>الدروس والنصائح للقادة</b><br/><br/>
تنصح خديجة جلولي رواد الأعمال بالتركيز على حل المشكلات الواقعية والمثابرة على الرغم من العقبات. تشدد على أهمية عدم الخوف من الفشل والتعلم سريعًا من الأخطاء. بالنسبة لها، تظل نصيحة أساسية: "دائمًا ما يكون الوقت مبكرًا جدًا للاستسلام." <br/><br/>
<b>المبادرات والشبكات</b><br/><br/>
تشارك خديجة جلولي بنشاط في مبادرات دعم رواد الأعمال. وهي متورطة في نادي أعمال النساء في منطقة الشرق الأوسط وشمال إفريقيا، وهي مبادرة مدعومة من الاتحاد من أجل المتوسط (UpM) ومنظمة الأمم المتحدة للتنمية الصناعية (UNIDO). يوفر هذا الشبك نظامًا بيئيًا لدعم النساء الرياديات في منطقة الشرق الأوسط وشمال إفريقيا.
`,
        imageUrl: `${process.env.PUBLIC_URL}/wikiphedia/khadija.jpg`,
    },
    {
        id: 2,
        title: "أيمن المسمودي: مبتكر يغير المعادلة للأشخاص ذوي الإعاقة في تونس",
        date: "",
        content: `<br/>أيمن المسمودي، شاب تونسي autodidact، يمثل قصة نجاح حقيقية. على الرغم من إعاقته، نجح في تحويل التحديات إلى فرص بفضل شغفه بالتكنولوجيا. تعلم أيمن بمفرده مهارات مثل تطوير التطبيقات، التصميم، وأمن المعلومات، مما أثبت أن الإرادة يمكن أن تتغلب على العقبات.<br/><br/>

في عام 2016، تميز بفوزه بجائزة أفضل تطبيق في مسابقة M-Dev تونس. يهدف تطبيقه، "Idifidek"، إلى مساعدة الأشخاص ذوي الإعاقة من خلال تسهيل التواصل وتمكينهم من مشاركة نداءات المساعدة عبر شبكة دعم. شكل هذا التطبيق نقطة تحول لأيمن، مما دفعه إلى الواجهة التكنولوجية في تونس.<br/><br/>

ومع ذلك، لا يكتفي أيمن بالابتكار على الصعيد التكنولوجي. فهو أيضًا مدافع عن حقوق الأشخاص ذوي الإعاقة. ملاحظًا نقص الدعم من الدولة والشركات لتوظيف الأشخاص ذوي الإعاقة، يناضل بنشاط من أجل إدماج أفضل. وفقًا له، "القوانين ليست كافية، يجب تطبيقها".<br/><br/>

كما يشارك أيمن في مبادرات مجتمعية في تونس تهدف إلى تحسين إمكانية الوصول إلى الأماكن العامة وتعزيز الإدماج. يستمر في المشاركة في المسابقات والمشاريع لدفع هذه القضية، بينما يظهر للشباب الذوي الإعاقة الآخرين أن لا شيء مستحيل مع التصميم.<br/><br/>
 

`,
        imageUrl: `${process.env.PUBLIC_URL}/wikiphedia/aymen.jpg`,
    },
    {
        id: 3,
        title: "بلال عمدوني: تحويل الشدائد إلى ابتكار، قصة مواطن تونسي",
        date: "",
        content: `
<b>شعيب نمري: شخصية ملهمة في مجال الهندسة والذكاء الاصطناعي في تونس</b><br/><br/>
في عالم حيث تدفع الهندسة والذكاء الاصطناعي (IA) حدود التكنولوجيا باستمرار، يبرز شعيب نمري كواحد من الشخصيات الأكثر إلهامًا في تونس. وُلِد في الثالة، ولاية القصرين، وتم تشخيصه منذ صغره بداء العضلات الخلقي من نوع أولريش، كان بإمكان شعيب أن يستسلم للتحديات التي تفرضها عليه حالته. لكن بدلاً من الاستسلام، حول كل عقبة إلى منصة للتميز في واحد من أكثر المجالات تطلبًا في العلوم.<br/><br/>
<b>جذور عميقة وتعليم ممتاز</b><br/><br/>
نشأ شعيب نمري في فوشانة، بن عروس، حيث أظهر منذ صغره قدرات فكرية غير عادية. على الرغم من الصعوبات البدنية والتحديات الدراسية، تميز في دراسته، حيث حصل على شهادة البكالوريا في القسم العلمي بمعدل 18.49 في عام 2010. أدت نجاحاته الأكاديمية إلى الالتحاق بالمعهد التحضيري للدراسات العلمية والتقنية (IPEST) في المرسى، حيث أعد لمتابعة مسيرته.<br/><br/>
بعد IPEST، التحق شعيب بالمدرسة الوطنية العليا للكهرباء والإلكترونيات والمعلوماتية والهيدروليكا والاتصالات (ENSEEIHT) في تولوز. لم يحقق النجاح فحسب، بل أنهى أيضًا كأول خريج في دفعته. ولم تتوقف طموحاته هنا: بل واصل صعوده بالحصول على دبلوم مزدوج في الهندسة الكهربائية والمعلوماتية من معهد جورجيا للتكنولوجيا، أحد أرقى الجامعات في الولايات المتحدة.<br/><br/>
<b>مسيرة تتسم بالابتكار والتميز</b><br/><br/>
تشهد مسيرة شعيب نمري بعد دراسته على إرادته في دفع حدود الهندسة. عمل أولاً لدى رينو، حيث ساهم في مشاريع تكنولوجية متعددة، قبل أن يتم اختياره لبرنامج تطوير الهندسة إيديسون المرموق التابع لشركة جنرال إلكتريك، وهو برنامج عالمي يعد المهندسين الشباب الواعدين ليصبحوا قادة في الابتكار.<br/><br/>
تميز عمله بشكل خاص في مجال الذكاء الاصطناعي. قام شعيب بتطوير حلول ذكاء اصطناعي ثورية في مجالات النقل والطاقة المتجددة، مما أضفى طابعًا جديدًا على التطبيقات التقليدية.<br/><br/>
<b>التحديات: التحول إلى فرص</b><br/><br/>
من خلال التحديات التي واجهها بسبب حالته، يصر شعيب على أهمية تمكين الأشخاص ذوي الإعاقة. فهو ينشر الوعي حول قضايا الإعاقة في المجتمع ويشجع الشباب الذوي الإعاقة على متابعة أحلامهم. ويقول: "الإعاقة ليست حجة، بل فرصة للابتكار والتفوق".<br/><br/>
<b>إرث مستمر ورؤية للمستقبل</b><br/><br/>
تأثير شعيب نمري يتجاوز نجاحاته الشخصية. يسعى إلى إنشاء شبكة من الأشخاص ذوي الإعاقة الذين يعملون في مجالات التكنولوجيا والهندسة. بفضل تصميمه، أصبح نموذجًا يُحتذى به للعديد من الشباب التونسيين، مشجعًا إياهم على عدم الاستسلام ومواصلة السعي لتحقيق أهدافهم، بغض النظر عن العقبات.<br/><br/>
أثبت شعيب نمري أن الإبداع والتصميم يمكن أن يحطما القيود، وهو رمز للفخر لبلده تونس.
`,
        imageUrl: `${process.env.PUBLIC_URL}/wikiphedia/bilel.jpg`,
    },
];
