// videosList.jsx

const videosar = [
    {
      url: 'https://youtu.be/BGtCaVhtQ20',
      title: 'مختلفون لكن مبدعون',
      description: "تسلط الفيديو 'مختلفون لكن مبدعون' الضوء على قوة التنوع والأصالة من خلال تقديم أمثلة ملهمة لأشخاص تجاوزوا اختلافاتهم للتفوق في مجالاتهم المختلفة. من خلال قصص محمد صلاح، لاعب كرة القدم الذي يتغلب على إعاقته البصرية، وسارة أحمد، الفنانة التي تعاني من شلل جزئي، ويوسف السيد، المبتكر التكنولوجي الذي يواجه صعوبات في التعلم، توضح الفيديو كيف يمكن للمواهب الفريدة أن تؤدي إلى نجاحات ملحوظة. كما يظهر أن التنوع في بيئات العمل والفرق يعزز الإبداع والابتكار، مما يشجع على ثقافة الشمول والتقدير للقدرات الفردية."
    },
    {
      url: 'https://www.youtube.com/watch?v=qlh6fNZRFS8',
      title: 'الحلقة 1: من الإصرار والإبداع إلى قمة النجاح',
      description: "تقدم هذه الفيديو قصة رائد أعمال ملهم تجاوز العديد من التحديات لتحقيق النجاح. تصف كيف، على الرغم من البداية الصعبة التي اتسمت بقلة الموارد المالية والمنافسة الشديدة، قام رائد الأعمال بالابتكار، وإقامة شراكات استراتيجية، واستخدام التقنيات الرقمية لتحويل عمله. تبرز الفيديو الإصرار والابتكار، والقيادة الملهمة كعوامل رئيسية في نجاحه، وتشجع رواد الأعمال المستقبليين على الإيمان بقدراتهم وعدم الاستسلام أمام العقبات."
    },
    {
      url: 'https://www.youtube.com/watch?v=NMah18ma_jQ',
      title: "الحلقة 2: درة وكوني بلا يدي لكنني أدرس طلابي",
      description: "تقدم هذه الفيديو القصة الملهمة لدرة التي، على الرغم من فقدان يديها، تواصل نقل معرفتها وشغفها لطلابها. تبرز الفيديو كيف تجاوزت درة تحدياتها الشخصية باستخدام طرق تعليمية إبداعية ومناسبة لإلهام وتحفيز طلابها. مثالها يوضح قوة الإصرار والالتزام في التعليم، موضحًا أن القيود الجسدية لا ينبغي أن تكون عقبة أمام التأثير الإيجابي في حياة الآخرين."
    },
    {
      url: "https://youtu.be/_XG2_R3oT0s",
      title: 'نحو مدينة شاملة الحلقة 1',
      description: "تستكشف الحلقة المعنونة 'نحو مدينة شاملة' المبادرات الرامية إلى جعل المدن أكثر إمكانية الوصول والشمولية للأشخاص ذوي الإعاقة. تبحث في التحديات الحالية المتعلقة بإمكانية الوصول في المدن، والسياسات التي تم تنفيذها لتحسين البنية التحتية والخدمات العامة، بالإضافة إلى الابتكارات التي تساهم في خلق بيئات حضرية أكثر عدلاً. تسلط الحلقة الضوء على أمثلة للممارسات الجيدة وشهادات للأشخاص ذوي الإعاقة، مع التأكيد على أهمية نهج تعاوني لتحقيق شمول حقيقي في المدن."
    },
    {
      url:"https://youtu.be/s8k12nhOVrQ",
      title: 'نحو مدينة شاملة الحلقة 2',
      description: "في مدينة صغيرة ساحرة، تعيش فتاة فضولية تدعى نورا مغامرات مشوقة مع كلبها السحري، بوبي. معًا، يستكشفون أسرار المدينة. في الوقت نفسه، تواجه دليلة، التي تستعد ليوم عملها الأول، عقبات أثناء رحلتها بسبب الأرصفة المزدحمة وإشارات المرور غير المناسبة للأشخاص ذوي الإعاقة. بفضل سحر بوبي، يتخيلون حلولًا مبتكرة مثل توسيع الأرصفة، وإضافة خطوط ملموسة لتوجيه المشاة، وإشارات صوتية تشير إلى متى يمكنهم العبور بأمان. عند وصولها إلى محطة الحافلات، تستخدم دليلة جهازًا صوتيًا لمعرفة وقت وصول حافلتها. في نهاية رحلتها، تدرك دليلة كم جعلت هذه التعديلات رحلتها أكثر أمانًا وسهولة. تفهم نورا وبوبي أهمية جعل الأماكن العامة أكثر إمكانية الوصول لمساعدة الأشخاص ذوي الاحتياجات الخاصة، مما يوضح التأثير الإيجابي للشمول والابتكار على الحياة اليومية."
    },
    {
      url: "https://youtu.be/6JYiCdqLeq4",
      title: 'التوعية',
      description: "تتناول الفيديو موضوع التوحد، مع تسليط الضوء على أهمية الدعم الجماعي للأطفال المصابين بالتوحد، بما في ذلك الأسرة والمدرسة والمتخصصين. تصف الفيديو التوحد كاضطراب في النمو العصبي يتم تشخيصه من خلال صعوبات في التواصل الاجتماعي واللغوي، وغالبًا ما يكون مرئيًا منذ سن عام ونصف. تشمل الأعراض نقص الاتصال بالعين، وتقليل التفاعل الاجتماعي، واللعب غير المعتاد. تستكشف الفيديو عوامل متعددة تسهم في ذلك مثل الجوانب الوراثية والبيئية والتغذوية، ومضاعفات الولادة. تبرز الفيديو 'صدمة التشخيص' للآباء، التي تتطلب دعمًا نفسيًا، وتؤكد على ضرورة وجود برنامج شمول مدرسي صارم. تعتبر توعية الآباء والنهج التعليمي المناسب أمرين حاسمين لتسهيل اندماج الأطفال المصابين بالتوحد في المجتمع."
    },
    {
      url:"https://youtu.be/vv5lrSpboQQ",
      title: "جاهزون لباريس 2024 - فريق تونس البارالمبي",
      description:`تقرير تم إنتاجه من أجل "ويكيثون من أجل الشمول - التعلم، الانخراط، الإلهام. حالة فريق تونس البارالمبي"، الذي نظم في 7 ديسمبر 2023 في تونس، تونس، من قبل مكتب اليونسكو في المغرب، بالتعاون مع اللجنة البارالمبية التونسية.`
    },
    {
      url:  "https://youtu.be/SySFrUJ8JJU",
      title: 'قصة أنس',
      description: "في هذه الفيديو، يشارك أب تجربته مع طفله المصاب بالتوحد. يصف كيف تم التشخيص عندما كان عمره عام ونصف وكيف لاحظ سلوكيات متكررة ونقصًا في التواصل. بفضل المساعدة المتخصصة والمتابعة المستمرة، يلاحظ تحسنًا ملحوظًا كل عام. يبرز الأب أهمية الدعم الأسري والمرافقة المناسبة، مشيرًا إلى أن ابنه قد تقدم في مهاراته وتفاعلاته الاجتماعية. يشجع الآباء على عدم التخلي عن أطفالهم، والإصرار، والإيمان بقدراتهم. وفقًا له، يمكن أن يؤدي الأمل والالتزام إلى تحسينات ملحوظة وتطور إيجابي."
    },
    {
      url: "https://youtu.be/ZqqT6P8vLI4",
      title: 'قصة فرح',
      description: "في هذا الشهادة، تتحدث السيدة عزيزة عثماني عن تجربتها كأم لطفل مصاب بالتوحد، تم اكتشافه في سن عام ونصف. تصف الصعوبات الأولية، والارتباك أمام التشخيص، والتحديات التي واجهتها عند دمج طفلها في الحياة اليومية. على الرغم من العقبات، تبرز التقدم الذي تم إحرازه بفضل الرعاية المتخصصة والدعم المناسب. تؤكد على أهمية التعليم والاندماج، مشددة على أن كل نجاح صغير هو خطوة نحو فهم أفضل وقبول للتوحد. وفقًا لها، فإن الصبر ودعم الآباء والجهود المستمرة هي أساسيات لمساعدة الطفل على الازدهار والنجاح."
    },
    {
      url: "https://youtu.be/y_-W7ggvNsI",
      title: 'قصة هارون',
      description: "في هذه الشهادة، يتحدث إبراهيم دريدي عن ابنه هارون، الذي تم تشخيصه باضطراب طيف التوحد. يشرح أن هارون، على الرغم من ولادته الطبيعية، أظهر علامات تطور غير طبيعي منذ صغره، مثل صعوبات في المشي أو التفاعل مع الأطفال الآخرين. بعد استشارة المتخصصين، اتخذ إبراهيم إجراءات للحد من تعرض ابنه للشاشات وأدخله في أنشطة مثل السباحة، التي ساهمت كثيرًا في تطويره البدني ورفاهيته العامة. يبرز أن تقدم هارون في أنشطة مثل السباحة كان ملحوظًا، ويعبر عن أمله أن يتمكن ابنه يومًا ما من العمل في مجالات مثل الحماية المدنية بفضل المهارات المكتسبة. يوصي الآباء الآخرين بالتركيز على نقاط قوة أطفالهم ودعمهم دون التركيز فقط على صعوباتهم."
    },
    {
      url: "https://youtu.be/f2sGbOD09Mc",
      title: 'قصة قصي',
      description: "في هذه الشهادة، تشرح أم التحديات التي تواجهها مع طفلها، الذي تم تشخيصه باضطراب طيف التوحد. في البداية، لم تلاحظ أي مشاكل كبيرة في تطور ابنها. ومع ذلك، في سن الثانية، بدأت تشعر بالقلق عندما لم يتقدم ابنها كما يفعل الأطفال الآخرون ولم يتحدث. بعد استشارة عدة متخصصين، أجريت لها مشاورات وبدأت في تنظيم أنشطة تفاعلية مع ابنها. تصف كيف ساعدها هؤلاء المتخصصون في فهم احتياجات ابنها، وتؤكد على أهمية التعليم المتخصص والإبداع في الأنشطة لمساعدة الأطفال المصابين بالتوحد في الازدهار والتقدم."
    },
      
  ];
  
  export default videosar;
  