export const Artistesitemsar = [
    {
        id: 1,
        title: "أشرف نمري: فن الرسم بالقدم، رمز للمرونة",
        date: "",
        content: `<b>مقدمة</b><br/><br/>
أشرف نمري، المولود عام 1986 في الكبارية، هو فنان تشكيلي تونسي يتجاوز حدود الفن. على الرغم من إعاقته الجسدية، فإنه يبدع أعمالًا رائعة باستخدام قدمه اليسرى فقط، ليصبح بذلك أول رسام تونسي معاق يحصل على بطاقة رسام محترف. تجسد مسيرته الشخصية والفنية المرونة والشغف والابتكار.<br/><br/>
<b>أسلوب وتقنيات</b><br/><br/>
أشرف نمري هو سيد في عدة تقنيات فنية، بما في ذلك الرسم الزيتي، والأكريليك، والتقنيات المختلطة. يتميز بقدرته على التقاط العمق والعاطفة في أعماله، مع حساسية ملونة ملحوظة. تكشف لوحاته، سواء كانت تصويرية أو تعبيرية، عن إتقان يثير الإعجاب من حيث التفاصيل والتأثير العاطفي. تبرز بورتريهاته، بشكل خاص، بقدرتها على التقاط جوهر موضوعاتها.<br/><br/>
<b>مصادر الإلهام</b><br/><br/>
تستند مصادر إلهام أشرف نمري بعمق إلى حبه للتاريخ والطبيعة، وخاصة الخيول، التي يعتبرها رموزًا للإرادة والتحدي. تنعكس شغفه بهذه الحيوانات غالبًا في أعماله، حيث تصبح الخيل استعارة قوية للقوة الداخلية. من خلال دمج مواضيعه المفضلة مع عناصر طبيعية وتاريخية، يخلق أشرف لوحات تتناغم مع تجاربه الحياتية، مترجمة مشاعره إلى أعمال نابضة بالحياة.<br/><br/>
<b>المواضيع المطروحة</b><br/><br/>
تدور المواضيع المركزية في أعمال أشرف نمري حول المرونة والتحدي والإرادة لتجاوز العقبات. واحدة من معارضه البارزة، "النار والجليد: صراع"، توضح ثنائية هذه المفاهيم، حيث تعرض بورتريهات لشخصيات تونسية إلى جانب تمثيلات رمزية للطبيعة والحيوانات. كل لوحة هي دعوة للغوص في عالمه الفريد والملهم.`,
        imageUrl: `${process.env.PUBLIC_URL}/wikiphedia/achref.jpg`,
    },
];
