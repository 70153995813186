export const Chercheursitemsar = [
    {
        id: 1,
        title: "وليد زيدي: مسيرة متألقة من المرونة والثقافة",
        date: "",
        content: `<b>وليد زيدي: رمز للمثابرة والابتكار الثقافي في تونس</b><br/><br/>
وليد زيدي، اسم يتردد كرمز للمثابرة والابتكار الثقافي في تونس. وُلِد في 30 أبريل 1986 في الكاف، وأصبح هذا الرجل الاستثنائي شخصية بارزة في المجتمع التونسي، ليس فقط لأنه تجاوز التحديات التي فرضها إعاقته البصرية، ولكن أيضًا لأنه ترك بصمة في التاريخ كأول وزير مكفوف في البلاد.<br/><br/>
<b>طريق نحو التميز</b><br/><br/>
على الرغم من العقبات الجسدية، لم يتوقف وليد زيدي عن السعي نحو التميز. منذ صغره، أظهر إصرارًا قويًا على تخطي التوقعات، وفرض نفسه كنموذج للنجاح. بعد دراسته في مدارس متخصصة، حصل على درجة الدكتوراه في الأدب من جامعة المنوبة في عام 2019. تعكس أبحاثه في العلوم البلاغية وعلم نفس الإعاقة إرادة عميقة لفهم وتحويل التصورات حول الإعاقة في المجتمع.<br/><br/>
كأستاذ وباحث في جامعة المنوبة، لا يكتفي وليد زيدي بالتفوق في العالم الأكاديمي. بل يستخدم معرفته لإلهام وتعليم الآخرين، ليصبح مصدر إلهام للشباب التونسي الذين يسعون لتجاوز عقباتهم الخاصة.<br/><br/>
<b>وزارة مضيئة</b><br/><br/>
في 2 سبتمبر 2020، حقق وليد زيدي خطوة جديدة في مسيرته من خلال توليه منصب وزير الشؤون الثقافية. كانت تعيينه، الذي يحمل رمزية كبيرة، إشارة للعالم بأن المواهب في تونس يمكن أن تتألق بغض النظر عن الظروف. على الرغم من أن فترة ولايته كانت قصيرة - انتهت في 5 أكتوبر 2020 - إلا أنه ترك انطباعًا قويًا من خلال إثبات أن الإدماج ليس مجرد مسألة تمثيل، بل هو رافعة قوية للتغيير الاجتماعي.<br/><br/>
<b>حياة م dedicatedة للثقافة</b><br/><br/>
وليد زيدي هو أكثر من مجرد رجل أدب وسياسة. فهو شاعر بالفطرة وموسيقي موهوب، يتقن فن العود، الآلة الشرقية التقليدية. شغفه بالثقافة لا يقتصر على إبداعاته الفنية الخاصة. يدير وليد ناديًا أدبيًا في تاجروين، حيث يدعم المواهب الناشئة، ويشارك معهم حبه للكلمات والألحان.<br/><br/>
إنه لا يلهم فقط كفنان، بل كشخصية تجسد المرونة. من خلال مسيرته، يثبت وليد زيدي أن الإعاقة ليست عائقًا أمام الإنجاز الشخصي أو التأثير الاجتماعي. يتحدى الأعراف ويكسر الحواجز، مشجعًا الآخرين على العثور على طريقهم الخاص في عالم قد يبدو مقيدًا.<br/><br/>
<b>إرث دائم</b><br/><br/>
إن إرث وليد زيدي لا يقتصر على ألقابه أو إنجازاته الأكاديمية. فهو يترك وراءه رسالة قوية: رسالة الأمل والإمكانية. كأول وزير مكفوف في تونس، فتح الطريق أمام الآخرين ليقتفوا أثره. تستمر رؤيته للإدماج والثقافة والمرونة في التأثير على الأجيال القادمة.`,
        imageUrl: `${process.env.PUBLIC_URL}/wikiphedia/walid.jpg`,
    },
    {
        id: 2,
        title: "شعيب نمري: مهندس عبقري يعيد تعريف المرونة والابتكار",
        date: "",
        content: `<b>شعيب نمري: رمز للمثابرة والابتكار الثقافي في تونس</b><br/><br/>
شعيب نمري، اسم يتردد كرمز للمثابرة والابتكار الثقافي في تونس. وُلِد في 30 أبريل 1986 في الكاف، وأصبح هذا الرجل الاستثنائي شخصية بارزة في المجتمع التونسي، ليس فقط لأنه تجاوز التحديات التي فرضها إعاقته البصرية، ولكن أيضًا لأنه ترك بصمة في التاريخ كأول وزير مكفوف في البلاد.<br/><br/>
<b>طريق نحو التميز</b><br/><br/>
على الرغم من العقبات الجسدية، لم يتوقف وليد زيدي عن السعي نحو التميز. منذ صغره، أظهر إصرارًا قويًا على تخطي التوقعات، وفرض نفسه كنموذج للنجاح. بعد دراسته في مدارس متخصصة، حصل على درجة الدكتوراه في الأدب من جامعة المنوبة في عام 2019. تعكس أبحاثه في العلوم البلاغية وعلم نفس الإعاقة إرادة عميقة لفهم وتحويل التصورات حول الإعاقة في المجتمع.<br/><br/>
كأستاذ وباحث في جامعة المنوبة، لا يكتفي وليد زيدي بالتفوق في العالم الأكاديمي. بل يستخدم معرفته لإلهام وتعليم الآخرين، ليصبح مصدر إلهام للشباب التونسي الذين يسعون لتجاوز عقباتهم الخاصة.<br/><br/>
<b>وزارة مضيئة</b><br/><br/>
في 2 سبتمبر 2020، حقق وليد زيدي خطوة جديدة في مسيرته من خلال توليه منصب وزير الشؤون الثقافية. كانت تعيينه، الذي يحمل رمزية كبيرة، إشارة للعالم بأن المواهب في تونس يمكن أن تتألق بغض النظر عن الظروف. على الرغم من أن فترة ولايته كانت قصيرة - انتهت في 5 أكتوبر 2020 - إلا أنه ترك انطباعًا قويًا من خلال إثبات أن الإدماج ليس مجرد مسألة تمثيل، بل هو رافعة قوية للتغيير الاجتماعي.<br/><br/>
<b>حياة م dedicatedة للثقافة</b><br/><br/>
وليد زيدي هو أكثر من مجرد رجل أدب وسياسة. فهو شاعر بالفطرة وموسيقي موهوب، يتقن فن العود، الآلة الشرقية التقليدية. شغفه بالثقافة لا يقتصر على إبداعاته الفنية الخاصة. يدير وليد ناديًا أدبيًا في تاجروين، حيث يدعم المواهب الناشئة، ويشارك معهم حبه للكلمات والألحان.<br/><br/>
إنه لا يلهم فقط كفنان، بل كشخصية تجسد المرونة. من خلال مسيرته، يثبت وليد زيدي أن الإعاقة ليست عائقًا أمام الإنجاز الشخصي أو التأثير الاجتماعي. يتحدى الأعراف ويكسر الحواجز، مشجعًا الآخرين على العثور على طريقهم الخاص في عالم قد يبدو مقيدًا.<br/><br/>
<b>إرث دائم</b><br/><br/>
إن إرث وليد زيدي لا يقتصر على ألقابه أو إنجازاته الأكاديمية. فهو يترك وراءه رسالة قوية: رسالة الأمل والإمكانية. كأول وزير مكفوف في تونس، فتح الطريق أمام الآخرين ليقتفوا أثره. تستمر رؤيته للإدماج والثقافة والمرونة في التأثير على الأجيال القادمة.`,
        imageUrl: `${process.env.PUBLIC_URL}/wikiphedia/chouaib.jpg`,
    },
];
