export const podcastEpisodesar =[
{
    id: 1,
    title: 'استمع إليّ الحلقة 1: التغيرات المناخية وتأثيراتها على الأشخاص ذوي الإعاقة',
    date: '1 أوت 2024',
    description: "في الحلقة الأولى من سلسلة البودكاست 'استمع إليّ' بعنوان 'التغيرات المناخية وتأثيراتها على الأشخاص ذوي الإعاقة'، التي تقدمها جيهان الزمزمي، يتم تناول كيفية تأثر الأشخاص ذوي الإعاقة بالتغيرات المناخية بشكل خاص. يستعرض البودكاست المخاطر المتزايدة التي يتعرض لها هؤلاء الأفراد بسبب الكوارث الطبيعية والظروف المناخية القاسية، والتحديات المتعلقة بالوصول إلى المرافق أثناء حالات الطوارئ. كما يناقش الاحتياجات الخاصة المتعلقة بالتحضير والاستجابة للأزمات، مع التأكيد على أهمية تعديل السياسات المناخية لتشمل الأشخاص ذوي الإعاقة وضمان سلامتهم ورفاهيتهم.",
    audioUrl: `${process.env.PUBLIC_URL}/audios/Ecoute-moiep1.mp4`,
    downloadUrl: `${process.env.PUBLIC_URL}/audios/Ecoute-moiep1.mp4`,
    imageUrl: `${process.env.PUBLIC_URL}/podcasts/saidafm.png`
},
{
    id: 2,
    title: 'استمع إليّ الحلقة 2',
    date: '8 أوت 2024',
    description: "تتناول الحلقة الثانية من البودكاست 'استمع إليّ' التي تقدمها جيهان الزمزمي التحديات التي يواجهها الأشخاص ذوي الإعاقة للوصول إلى المعلومات. تستعرض الحلقة العقبات المتعلقة بالصيَغ غير القابلة للوصول، والفجوات في القوانين الحالية، والمبادرات التي تهدف إلى تحسين الوصول. من خلال شهادات وأمثلة واقعية، تبرز الحلقة الجهود المبذولة لتجاوز هذه العقبات، مع دعوة لزيادة الوعي واتخاذ إجراءات عملية لضمان وصول متساوٍ إلى المعلومات للجميع.",
    audioUrl: `${process.env.PUBLIC_URL}/audios/Ecoute-moiep2.mp4`,
    downloadUrl: `${process.env.PUBLIC_URL}/audios/Ecoute-moiep2.mp4`,
    imageUrl: `${process.env.PUBLIC_URL}/podcasts/saidafm.png`
},
{
    id: 3,
    title: 'بودكاست خارقون (ياسين)',
    date: '15 أوت 2024',
    description: "يروي هذا البودكاست القصة الملهمة لياسين محمد، شاب يبلغ من العمر 24 عامًا من القصرين، تونس. ياسين، الذي يعاني من فقدان البصر، يشارك مسيرته، وتحدياته، ونجاحاته رغم الصعوبات. وُلِدَ بإعاقة بصرية ويصف كيف نشأ في منطقة مهمشة تاريخيًا. يتحدث عن أهمية العائلة، ويذكر أخته الكبرى التي هي أيضًا كفيفة والتي يعتبرها قدوة ومصدر إلهام. وعلى الرغم من العقبات المرتبطة بحالته وموقعه الجغرافي، يعبر ياسين عن فخره كتونسي وإصراره على تحقيق طموحاته. يبرز شغفه باللغات والحضارات ورغبته في المساهمة الإيجابية في المجتمع. كما يشير إلى نقص الدعم من الدولة للأشخاص ذوي الإعاقة وأهمية الإدماج الاجتماعي والتعليمي. إن تفاؤله، ومثابرته، وقدرته على نشر الفرح من حوله تجعل من قصته شهادة قوية على الصمود والنجاح.",
    audioUrl: `${process.env.PUBLIC_URL}/audios/yassine.mp3`,
    downloadUrl: `${process.env.PUBLIC_URL}/audios/yassine.mp3`,
    imageUrl: `${process.env.PUBLIC_URL}/podcasts/backcard.jpg`
},
{
    id: 4,
    title: 'الإنتاج الإعلامي الشامل: الحلقة 1',
    date: '15 أوت 2024',
    description: "في البودكاست الخاص بمشروع 'لتحسين وصول الأشخاص ذوي الإعاقة إلى الخدمات والحقوق في تونس'، نستعرض المبادرات التي تهدف إلى ضمان إدماج أفضل للأشخاص ذوي الإعاقة في البلاد. يتطرق البودكاست إلى الجهود المبذولة لجعل الخدمات العامة والحقوق متاحة للجميع، مع تسليط الضوء على التحديات والحلول المقترحة لتحسين الاندماج. كما يعرض شهادات لأشخاص من ذوي الإعاقة وتوصيات لتعزيز السياسات والبنية التحتية لضمان تكافؤ الفرص في الوصول إلى الخدمات والحقوق لجميع المواطنين التونسيين.",
    audioUrl: `${process.env.PUBLIC_URL}/audios/DreamFMEpisode1.mp4`,
    downloadUrl: `${process.env.PUBLIC_URL}/audios/DreamFMEpisode1.mp4`,
    imageUrl: `${process.env.PUBLIC_URL}/podcasts/backcard.jpg`
},
{
    id: 5,
    title: 'الإنتاج الإعلامي الشامل: الحلقة 2',
    date: '15 أوت 2024',
    description: "في هذا البودكاست حول حقوق الأشخاص ذوي الإعاقة في العمل وفق التشريعات التونسية، في إطار مشروع 'لتحسين وصول الأشخاص ذوي الإعاقة إلى الخدمات والحقوق في تونس'، يتم تسليط الضوء على النصوص القانونية التي تضمن تكافؤ الفرص في العمل للأشخاص ذوي الإعاقة. تناقش الحلقة القوانين التونسية التي تهدف إلى تسهيل إدماجهم في سوق العمل، وكذلك التدابير الداعمة والتسهيلات داخل المؤسسات. كما تبرز التحديات المستمرة والجهود المطلوبة لتعزيز تطبيق هذه الحقوق، من أجل ضمان وصول عادل إلى الفرص المهنية وبيئة عمل شاملة.",
    audioUrl: `${process.env.PUBLIC_URL}/audios/DreamFMEpisode2.mp4`,
    downloadUrl: `${process.env.PUBLIC_URL}/audios/DreamFMEpisode2.mp4`,
    imageUrl: `${process.env.PUBLIC_URL}/podcasts/backcard.jpg`
},
{
    id: 6,
    title: "أمل الحلقة 1",
    date: "1 أوت 2024",
    description: "يقدم محتوى التسجيل الصوتي دليلاً حول ريادة الأعمال النسائية موجهًا للنساء ذوات الإعاقة، من إنتاج شركة كورتابل بدعم من منظمة اليونسكو في تونس. تشرح أمل، الراوية، أنه بعد مغادرتها جامعة إتّق، بدأت مسيرتها لتطوير مهاراتها المهنية. تشارك ملاحظاتها حول التحديات التي تواجه الأشخاص ذوي الإعاقة، بما في ذلك نقص الوصول إلى البنى التحتية الملائمة مثل المصاعد. تؤكد أمل على فكرة أنه رغم القوة الداخلية لكل فرد، من الصعب النجاح بمفرده. ثم تروي لقاءها مع صديقتها سليمة التي تدير مشروعًا في الصناعات الإبداعية والثقافية. شاركت سليمة تجربتها وأكدت على أهمية الدعم الذي تلقته من مرشديها ومستشاريها في مسيرتها المهنية. يهدف هذا الدليل المرئي إلى استعراض التحديات التي تواجهها النساء رائدات الأعمال وإظهار كيفية التغلب عليها، آملًا أن يتعلم المشاهدون من التجارب المشاركة ويجدوا الإلهام والحلول.",
    audioUrl: `${process.env.PUBLIC_URL}/audios/episode1.mp3`,
    downloadUrl: `${process.env.PUBLIC_URL}/audios/episode1.mp3`,
    imageUrl: `${process.env.PUBLIC_URL}/podcasts/backcard1.jpg`
},
{
    id: 7,
    title: "أمل الحلقة 2",
    date: "8 أوت 2024",
    description: "في هذا البودكاست، تشارك رشا رحلتها الملهمة في تجاوز العقبات المتعلقة بإعاقتها لتحقيق أحلامها. واجهت في البداية صعوبات في العثور على تخصصات دراسية تتناسب مع اهتماماتها، ولكنها أمضت عامًا كاملاً تتعلم الرسم والفن بشكل ذاتي. قررت رشا بعد ذلك دراسة إدارة الأعمال لاكتساب المهارات اللازمة لإنشاء مشروعها الفني. ورغم نقص التمويل الأولي، وجدت دعمًا من جمعية وتمكنت من إطلاق مشروعها. تشكر عائلتها وكل من ساندها، مشددة على أهمية العزيمة والدعم الخارجي لتحقيق الطموحات.",
    audioUrl: `${process.env.PUBLIC_URL}/audios/episode2.mp3`,
    downloadUrl: `${process.env.PUBLIC_URL}/audios/episode2.mp3`,
    imageUrl: `${process.env.PUBLIC_URL}/podcasts/backcard1.jpg`
},
{
    id: 8,
    title: "أمل الحلقة 3",
    date: "15 أوت 2024",
    description: "في هذا البودكاست، تروي أنيك تجربتها كريادية أنشأت مشروعها الخاص دون دعم مالي أو مساعدة مؤسساتية. تشرح كيف اعتمدت على أسرتها وأصدقائها لدعمها في مبادراتها، مشيرة إلى أن الموارد المتاحة لرواد الأعمال ذوي الإعاقة غالبًا ما تكون محدودة وغير كافية. ورغم العقبات، نجحت أنيك في تحويل شغفها إلى مشروع فني، رغم أن قطاع الفن لا يحظى بالأولوية من قبل المستثمرين وبرامج التمويل التي تفضل عمومًا المشاريع الزراعية. تتطلع أنيك إلى تنظيم معارض دولية لعرض أعمالها لجمهور أوسع، لكنها تواجه تحديات لوجستية، بما في ذلك النقل والتمويل. تشجع النساء الأخريات اللواتي يطمحن إلى ريادة الأعمال على عدم التخلي عن أحلامهن، مهما كانت الصعوبات، وتؤكد على أهمية متابعة المشاريع التي تثير شغفهن لتحقيق المثابرة في مواجهة التحديات.",
    audioUrl: `${process.env.PUBLIC_URL}/audios/episode3.mp3`,
    downloadUrl: `${process.env.PUBLIC_URL}/audios/episode3.mp3`,
    imageUrl: `${process.env.PUBLIC_URL}/podcasts/backcard1.jpg`
},
{
    id: 9,
    title: "أمل الحلقة 4",
    date: "15 أوت 2024",
    description: "في البودكاست الخاص بمشروع 'من أجل تحسين الوصول إلى الخدمات والحقوق للأشخاص ذوي الإعاقة في تونس'، يتم استعراض المبادرات الرامية لضمان دمج أفضل للأشخاص ذوي الإعاقة في البلاد. يتناول البودكاست الجهود المبذولة لجعل الخدمات العامة والحقوق متاحة، مع التركيز على التحديات التي تم مواجهتها والحلول التي تم وضعها لتحسين الاندماج. كما يسلط الضوء على شهادات الأشخاص ذوي الإعاقة وتوصيات لتعزيز السياسات والبنية التحتية لضمان المساواة في الوصول إلى الخدمات والحقوق لجميع المواطنين التونسيين.",
    audioUrl: `${process.env.PUBLIC_URL}/audios/episode4.mp3`,
    downloadUrl: `${process.env.PUBLIC_URL}/audios/episode4.mp3`,
    imageUrl: `${process.env.PUBLIC_URL}/podcasts/backcard1.jpg`
},
{
    id: 10,
    title: "أمل الحلقة 5",
    date: "15 أوت 2024",
    description: "يناقش البودكاست الدور الهام للجمعيات والمنظمات غير الحكومية في دعم النساء الرياديات، خاصة ذوات الإعاقة. يبرز أهمية الجمعيات في التوعية بحقوق الأشخاص ذوي الإعاقة وتقديم الدعم العملي من خلال البرامج والمشاريع. في تونس، تلعب عدة منظمات مثل الاتحاد الوطني للمكفوفين والاتحاد التونسي لمساندة الأشخاص ذوي الإعاقة دورًا أساسيًا في هذا الدعم. كما يسلط البودكاست الضوء على أفضل الممارسات للتواصل الفعّال داخل الشركات، مشددًا على ضرورة وجود رؤية واضحة وأهداف محددة واستراتيجية تواصل محكمة لضمان نجاح الشركات ورضا العملاء.",
    audioUrl: `${process.env.PUBLIC_URL}/audios/episode5.mp3`,
    downloadUrl: `${process.env.PUBLIC_URL}/audios/episode5.mp3`,
    imageUrl: `${process.env.PUBLIC_URL}/podcasts/backcard1.jpg`
},
{
    id: 11,
    title: "أمل الحلقة 6",
    date: "15 أوت 2024",
    description: "تصف الحلقة أنشطة جمعية إبصار، التي تهتم بالأشخاص ذوي الإعاقة، لا سيما النساء. تنظم الجمعية فعاليات ثقافية مثل مهرجان دولي للموسيقيين والمبدعين من ذوي الإعاقة، وتشجع مشاركة النساء ذوات الإعاقة في الأنشطة الحرفية مثل صناعة الصوف والخزف. كما تدعم الجمعية الوصول إلى الثقافة من خلال تكييف كتب الأطفال بلغة البرايل وتوفير الموارد التعليمية عبر منصة إلكترونية. تعمل الجمعية على تعزيز الإدماج والتمكين للأشخاص ذوي الإعاقة من خلال الثقافة والتعليم وبناء العلاقات.",
    audioUrl: `${process.env.PUBLIC_URL}/audios/episode6.mp3`,
    downloadUrl: `${process.env.PUBLIC_URL}/audios/episode6.mp3`,
    imageUrl: `${process.env.PUBLIC_URL}/podcasts/backcard1.jpg`
}
];